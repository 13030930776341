import React from "react";
// import play from "../../../assets/play.svg";
// import friends from "../../../assets/friends.svg";
// import tasks from "../../../assets/tasks.svg";
// import buyCoins from "../../../assets/buy-coins.svg";
import { ReactComponent as PlayIcon } from '../../../assets/play.svg';
import { ReactComponent as TasksIcon } from '../../../assets/tasks.svg';
import { ReactComponent as FriendsIcon } from '../../../assets/friends.svg';
import { ReactComponent as BuyCoinsIcon } from '../../../assets/buy-coins.svg';
import { ReactComponent as RedeemIcon } from '../../../assets/redeem-icon.svg';
import { PATH_EARNED, PATH_MAIN, PATH_REDEEM, PATH_TASKS } from "../../../constants/Paths";
import { useNavigate } from "react-router-dom";
import { getLoyaltyData } from "../../../logic/server/api";
import { setAmount } from "../../../screens/main/counterSlice";
import { incrementBlinkByAmount } from "../../../screens/main/blinkSlice";
import { incrementLoveByAmount } from "../../../screens/main/loveSlice";
const FooterFunction = ({ currentPage }) => {
    const navigate = useNavigate();


    // useEffect(() => {
    //     const imagesPreload = [play, friends, tasks, buyCoins];
    //     imagesPreload.forEach((image) => {
    //         const newImage = new Image();
    //         newImage.src = image;
    //         window[image] = newImage;
    //     });
    // }, []);

    return (
        <div >
            <div className='footer-container'>
                <div className={`footer__cards ${currentPage === 'main' ? 'footer__cards--active' : ''}`}
                    onClick={() => navigate(PATH_MAIN)}>
                    <PlayIcon className={'footer__icon'} />
                    <div className={`forms__bottom-results-container forms__bottom-icon--day-mode`}>
                        <div className='footer__text text-regular'>Play</div>
                    </div>
                </div>
                <div className={`footer__cards ${currentPage === 'redeem' ? 'footer__cards--active' : ''}`}
                    onClick={() => navigate(PATH_REDEEM)}>
                    <RedeemIcon className={'footer__icon'} />
                    <div className='forms__bottom-results-container'>
                        <div className='footer__text text-regular'>Redeem</div>
                    </div>
                </div>
                <div className={`footer__cards ${currentPage === 'tasks' ? 'footer__cards--active' : ''}`}
                    onClick={() => navigate(PATH_TASKS)}>
                    <TasksIcon className={'footer__icon'} />
                    <div className='forms__bottom-results-container'>
                        <div className='footer__text text-regular'>Tasks</div>
                    </div>
                </div>
                <div className={`footer__cards ${currentPage === 'earnings' ? 'footer__cards--active' : ''} disabled`}
                    onClick={() => navigate(PATH_EARNED)}>
                    <FriendsIcon className={'footer__icon'} />
                    <div className='forms__bottom-results-container'>
                        <div className='footer__text text-regular'>Friends</div>
                    </div>
                </div>
                <div className='footer__cards disabled'>
                    <BuyCoinsIcon className={'footer__icon'} />
                    <div className='forms__bottom-results-container'>
                        <div className='footer__text text-regular'>Buy coins</div>
                    </div>
                </div>
            </div>
            <div className='forms__disclaimer'>
                Cats make no promise of da hooman money. You give taps.<br className='breakline'></br>
                We make purrs, blinks, n love. Cat is un financial advisor.<br className='breakline'></br>
                Kat fur game may look different in future as updoots are mead.
            </div>
        </div>
    );
};

export default FooterFunction;
