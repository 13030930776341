import React, { useEffect, useState } from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
// import {useTelegram} from "../../hooks/useTelegram";
import FooterFunction from "../../components/app/functions/FooterFunction";
import { ReactComponent as UserIcon } from '../../assets/user-icon.svg';
import { ReactComponent as VideoIcon } from '../../assets/video-icon.svg';
import { claimPoints, getTasks, setInteraction } from "../../logic/server/api";
import { useTelegram } from "../../hooks/useTelegram";
import { ToastContainer, toast } from 'react-toastify';

export const TaskType = {
    visited: 'interaction-tracker.link-visited',
    referral: 'referral-number',
    click: 'click',
};

const TasksPage = () => {
    const { webApp, user } = useTelegram();
    // const [tasks, setTasks] = useState([]);
    // const [tasksFollowPartner, setPartnersTasks] = useState([]);
    const [tasksFollowChannel, setChannelTasks] = useState([]);
    const [linkOpened, setLinkOpened] = useState([]);
    const [taskClaimed, setTaskClaimed] = useState([]);

    useEffect(() => {
        getTasksRequest();
    }, []);

    useEffect(() => {
        getTasksRequest();
    }, [linkOpened, taskClaimed]);

    async function getTasksRequest() {
        const resp = await getTasks();
        const followChannel = resp?.tasks?.filter(item => item?.conditions[0]?.type === 'interaction-tracker.link-visited');
        setChannelTasks(followChannel?.length ? followChannel : []);
        // setTasks(resp?.tasks);
    }

    async function handleInteraction(task) {
        if (task?.conditions[0].data?.link && (!task?.completion.claimable && task?.completion.status !== 'completed' )) {
        // if (task?.conditions[0].data?.link && (!task?.completion.claimable && task?.completion.status !== 'completed')) {
            // console.log(task);
            const res = await setInteraction(task?.conditions[0].data?.link);
            // window.open(task?.conditions[0].data?.link, '_blank');
            // window.location.assign(task?.conditions[0].data?.link);
            webApp.openLink(task?.conditions[0].data?.link);

            // window.location.href = task?.conditions[0].data?.link;
            if (res?.status) {
                setLinkOpened([{ id: task.id, status: true }]);
            }
        } else if (task?.completion.claimable) {
            const res = await claimPoints(localStorage.getItem('token'), task.id);
            if (res?.status) {
                setTaskClaimed([{ id: task.id, status: true }]);
            }
            toast.success(`You claimed ${task?.reward?.data?.value} coins successfully!`);

        }
    }

    // function claimAllRewards() {
    //     tasks.map(task => {
    //         if (task?.completion.claimable) {
    //             handleClaim(task);
    //         }
    //     });
    // }

    return (
        <TelegramScreen
            className={`main-wrapper`} id='gesuredZone'>
            <div className='main-container main-container-dim'>
                <div className={'cards-container--top'}>
                    <div className={'card__tasks--title '}>
                        <p className={'text-title-knewawe card__tasks--title-accent'}>Daily tasks:</p>
                        <p className={'text-bold card__tasks--title-text'}>{tasksFollowChannel.length} available</p>
                    </div>
                    <div className={'cards__container'}>
                        {tasksFollowChannel.map((task) => (
                            <div className={`card__task ${task?.completion.status === 'completed' ? 'disabled--bright' : ''}`}
                                key={task}
                                onClick={() => handleInteraction(task)}
                            >
                                {task?.conditions[0]?.type === TaskType.visited && <UserIcon />}
                                {task?.conditions[0]?.type === TaskType.referral && <UserIcon />}
                                {task?.conditions[0]?.type === TaskType.click && <VideoIcon />}
                                <div className={'card__column-to-row'}>
                                    <p className={'card__task--text'}>{task?.title}</p>
                                    <p className={'card__task--text'}>{task?.reward?.data?.value} coins for task</p>
                                </div>

                                {(!task?.completion.claimable && task?.completion.status !== 'completed') && <p className={'card__task--text-accent text-bold'}>APPLY</p>}
                                {(task?.completion.status === 'completed') && <p className={'card__task--text-accent text-bold'}>CLAIMED</p>}
                                {task?.completion.claimable && <p className={'card__task--text-accent text-bold'}>CLAIM</p>}
                            </div>
                        ))}
                    </div>
                </div>
                <div className='forms__bottom'>
                    <FooterFunction currentPage={'tasks'} />
                </div>
            </div>
            <ToastContainer
                position="bottom-center"
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                autoClose={3000}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </TelegramScreen>)

}
export default TasksPage;
