import { createSlice } from '@reduxjs/toolkit'

export const loveSlice = createSlice({
    name: 'love',
    initialState: {
        count: 0
    },
    reducers: {
        incrementLove: (state) => {
            state.count += 1
        },
        incrementLoveByAmount: (state, action) => {
            state.count += action.payload
        }
    }
})


export const {
    incrementLove,
    incrementLoveByAmount
} = loveSlice.actions

export default loveSlice.reducer
