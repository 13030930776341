import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import FooterFunction from "../../components/app/functions/FooterFunction";
import {ReactComponent as ArrowIcon} from '../../assets/arrow-right-icon.svg';

import {ReactComponent as CryptoIcon} from '../../assets/solar_close-circle-linear.svg';
import {
    deleteWalletAddress,
    getCustomersWallet,
    getLoyaltyData,
    registerInAffiliate,
    setWalletAddress
} from "../../logic/server/api";
import {PublicKey} from "@solana/web3.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {setAmount} from "../main/counterSlice";
import {incrementBlinkByAmount} from "../main/blinkSlice";
import {incrementLoveByAmount} from "../main/loveSlice";

const Claim = () => {
    const dispatch = useDispatch();

    const [walletAddress, SetWalletAddress] = useState('');
    const [error, SetError] = useState(false);
    const [walletAddressSaved, SetWalletAddressSaved] = useState('');

    let count = useSelector(state => state.counter.count);
    count = count || localStorage.getItem('points');
     useEffect(() => {
        getWallet();
        getAffiliatesData();
    }, []);


    async function getAffiliatesData() {
        const customerId = localStorage.getItem('customer');
        const token = localStorage.getItem('token');
        if (customerId && token) {
            const resp = await getLoyaltyData(customerId, token);
            if (resp && resp?.data?.reward) {
                const points = resp?.data?.reward?.loyalty_points;
                localStorage.setItem('code', resp?.data?.referral_code);

                const blinksCount = !!points ? Math.floor(Number(points) / 10) : 0;
                const loveCount = !!points ? Math.floor(Number(points) / 100) : 0;
                dispatch(setAmount(points));
                dispatch(incrementBlinkByAmount(blinksCount));
                dispatch(incrementLoveByAmount(loveCount));
                localStorage.setItem('points', points);
            }
        }
    }
    async function getWallet() {
        const resp = await getCustomersWallet();
        if (resp?.person?.crypto_wallet?.address) {
            SetWalletAddress(resp?.person?.crypto_wallet?.address);
            SetWalletAddressSaved(resp?.person?.crypto_wallet?.address);
        }
        console.log(resp);
    }
    async function deleteWallet() {
        return await deleteWalletAddress(walletAddress);
    }
    async function addWallet(walletToSave) {
        return await setWalletAddress(walletToSave);
    }
    const handleChange = (event) => {
        SetError(false);
        // console.log(event.target.value);
        SetWalletAddress(event.target.value);
    };

    function handleSave() {


        if (!walletAddressSaved && (walletAddress.length >= 35 && walletAddress.length < 60) ) {
            setWallet(walletAddress);
        } else if (walletAddressSaved) {
            navigateToWeb();
        }
    }
    const clearWallet = (event) => {
        if (walletAddressSaved) {
            deleteWallet().then(res => {
                SetWalletAddress('');
                SetWalletAddressSaved('');
            }).catch(err => {
                toast.error(err?.response?.data?.error);
            });
        } else {
            SetWalletAddress('');
            SetWalletAddressSaved('');
        }
    };
    function setWallet(address) {
        const walletToSave = address.trim();
        if (walletToSave) {
            try {
                if (walletAddressSaved) {
                    deleteWallet().then(() => {
                        addWallet(walletToSave).then(() => {
                            SetWalletAddressSaved(walletToSave);
                            toast.success('Wallet address saved successfully!');
                        }).catch(err => {
                            SetWalletAddressSaved('');
                            SetError(true);
                            toast.error(err?.response?.data?.error);
                        });
                    }).catch(err => {
                        SetWalletAddressSaved('');
                        SetError(true);
                        toast.error(err?.response?.data?.error);
                    });
                } else {
                    addWallet(walletToSave).then(() => {
                        SetWalletAddressSaved(walletToSave);
                        toast.success('Wallet address saved successfully!');
                    }).catch(err => {
                        SetError(true);
                        toast.error(err?.response?.data?.error);
                    });
                }
            } catch (err) {
                toast.error(err?.response?.data?.error);
                return false;
            }
        }
    }


    const navigateToWeb = () => {
        window.open('https://preprod.tapcat-memecoin.techfunder.de/redeem', '_blank', "noopener,noreferrer")
    }

        return (
        <TelegramScreen
            className={`main-wrapper`}  id='gesuredZone'>
            <div className='main-container main-container-dim'>
                <div className={'top-container'}>
                <div className={'redeem-cards-container gap-of-wrapper-of-ppurs'}>
              
                    <div>
                    <p className={'text-bold card__tasks--title-text'}>Redeem your tokens</p>
                        
                        
                    </div>
                    
                      <div className='wrapper-of-ppurs'>
                        
                          <p className='forms__bottom-text-title text-bold counter-of-ppurs'>{(!!count && count) || 0}</p>
                          
                          <p className='purr-counter-text'>PURR</p>
                          
                        </div>
                          
                
                    <div className='wrapper-for-crypto-wallet'>
                    
                     <form className='form-for-crypto-wallet'>
                      <label className='label-for-crypto-wallet'>
                        Connected wallet (Solana)
                         </label>
                        <input
                          type="text"
                          className={`input-for-crypto-wallet ${error ? 'input-error-text' : ''} ${walletAddressSaved ? 'disabled' : ''}`}
                          placeholder="2g9n8g48c..."
                          onChange={handleChange}
                          value={walletAddress}
                          required
                        />
                         <div className={'crypto-wallet-buttons'}>
                         <CryptoIcon className={`crypto-icon ${walletAddress? '' : 'disabled'}`} width="22" height="22" fill="#000"
                                         onClick={clearWallet}/>
                         {/*<ArrowIcon className={`set-wallet ${walletAddressSaved ? 'hide-button' : 'show-button'}`} onClick={handleSave}></ArrowIcon>*/}
                         </div>
                    </form>
                    
                  <div className={`cards__redeem-button redeem-text-btn  ${walletAddress ? '' : 'disabled'}`} onClick={handleSave}>
                      {`${walletAddressSaved ? 'Redeem Now' : 'Save wallet'}`}
                      <ArrowIcon/>
                  </div>
                    
                    </div>
                    
                </div>
                </div>
                <div className='forms__bottom'>
                    <FooterFunction currentPage={'redeem'}/>
                </div>
            </div>
                  <ToastContainer
                      position="bottom-center"
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      autoClose={3000}

                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                />

        </TelegramScreen>)

}
export default Claim;
