import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './screens/main/counterSlice.js'
import blinkReducer from './screens/main/blinkSlice.js'
import customerReducer from "./screens/main/customerSlice";
import loyaltyProgramSlice from "./screens/main/loyaltyProgramSlice";
import loveReducer from "./screens/main/loveSlice";
export default configureStore({
    reducer: {
        counter: counterReducer,
        blink: blinkReducer,
        customer: customerReducer,
        loyalty: loyaltyProgramSlice,
        love: loveReducer
    }
})
