import { createSlice } from '@reduxjs/toolkit'

export const loyaltyProgramSlice = createSlice({
    name: 'loyalty',
    initialState: {
        data: {}
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload
        },
    }
})


export const {
    setData
} = loyaltyProgramSlice.actions

export default loyaltyProgramSlice.reducer
