// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: var(--tg-theme-bg-color);
    color: var(--tg-theme-text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(#010202, #6CCFD7);
}

button {
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
}




.title {
    position: absolute;
    top: 100px;
    left: 0;
    color: white !important;
    font-size: 30px;
    z-index: 10000;
}
.back-btn {
    position: absolute;
    color: white !important;
    z-index: 1000;
    top: 10px;
    left: 0;
    font-size: 20px;
    width: 74px !important;
}
.btn-main {
    z-index: 0;
    position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,oCAAoC;IACpC,iCAAiC;IACjC,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,6CAA6C;AACjD;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,gBAAgB;AACpB;;;;;AAKA;IACI,kBAAkB;IAClB,UAAU;IACV,OAAO;IACP,uBAAuB;IACvB,eAAe;IACf,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,aAAa;IACb,SAAS;IACT,OAAO;IACP,eAAe;IACf,sBAAsB;AAC1B;AACA;IACI,UAAU;IACV,kBAAkB;AACtB","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n\nbody {\n    background: var(--tg-theme-bg-color);\n    color: var(--tg-theme-text-color);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    background: linear-gradient(#010202, #6CCFD7);\n}\n\nbutton {\n    background: none;\n    border: none;\n    outline: none;\n    box-shadow: none;\n}\n\n\n\n\n.title {\n    position: absolute;\n    top: 100px;\n    left: 0;\n    color: white !important;\n    font-size: 30px;\n    z-index: 10000;\n}\n.back-btn {\n    position: absolute;\n    color: white !important;\n    z-index: 1000;\n    top: 10px;\n    left: 0;\n    font-size: 20px;\n    width: 74px !important;\n}\n.btn-main {\n    z-index: 0;\n    position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
