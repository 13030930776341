import { createSlice } from '@reduxjs/toolkit'

export const blinkSlice = createSlice({
    name: 'blink',
    initialState: {
        count: 0
    },
    reducers: {
        incrementBlink: (state) => {
            state.count += 1
        },
        incrementBlinkByAmount: (state, action) => {
            state.count += action.payload
        }
    }
})


export const {
    incrementBlink,
    incrementBlinkByAmount
} = blinkSlice.actions

export default blinkSlice.reducer
