import React, {useEffect, useState} from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
// import {useTelegram} from "../../hooks/useTelegram";
import FooterFunction from "../../components/app/functions/FooterFunction";
import {ReactComponent as UserIcon} from '../../assets/user-icon.svg';
import {ReactComponent as VideoIcon} from '../../assets/video-icon.svg';
import {getTasks} from "../../logic/server/api";

const TasksPage = () => {
    // const {webApp, user} = useTelegram();
    // const [tasks, setTasks] = useState([]);
    const [tasksFollowPartner, setPartnersTasks] = useState([]);
    const [tasksFollowChannel, setChannelTasks] = useState([]);
    useEffect(() => {
        setPartnersTasks([1,2,3,4, 5]);
        setChannelTasks([1,2]);
        getTasksRequest();
    }, []);

    async function getTasksRequest() {
        const resp = await getTasks();
        console.log(resp);
    }
    return (
        <TelegramScreen
            className={`main-wrapper`}  id='gesuredZone'>
            <div className='main-container main-container-dim'>
                <div className={'cards-container--top'}>
                <div className={'card__tasks--title '}>
                    <p className={'text-title-knewawe card__tasks--title-accent'}>Daily tasks:</p>
                    <p className={'text-bold card__tasks--title-text'}>{[...tasksFollowChannel, ...tasksFollowPartner].length} available</p>
                </div>
                <div className={'cards__container'}>
                    {tasksFollowPartner.map((task) => (
                        <div className={'card__task'}
                             key={task}
                        >
                        <UserIcon/>
                        <div className={'card__column-to-row'}>
                            <p className={'card__task--text'}>Follow our partner # {1}</p>
                            <p className={'card__task--text'}>{250} coins for task</p>
                        </div>
                        <p className={'card__task--text-accent text-bold'}>CLAIM</p>
                    </div>
                    ))}
                    {tasksFollowChannel.map((task) => (
                    <div className={'card__task'}>
                        <VideoIcon/>
                        <div className={'card__column-to-row'}>
                            <p className={'card__task--text'}>Follow our partner channel #3</p>
                            <p className={'card__task--text'}>{250} coins for task</p>
                        </div>
                        <p className={'card__task--text-accent text-bold'}>CLAIM</p>
                    </div>
                    ))}
                </div>
                </div>
                <div className='forms__bottom'>
                    <FooterFunction currentPage={'tasks'}/>
                </div>
            </div>
        </TelegramScreen>)

}
export default TasksPage;
