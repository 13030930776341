import './App.css';
import {Route, Routes} from "react-router-dom";
import Main from "./screens/main/MainScreen";
import {
    PATH_EARNED,
    PATH_MAIN, PATH_REDEEM, PATH_TASKS
} from "./constants/Paths";

import {useEffect} from "react";
import {useTelegram} from "./hooks/useTelegram";
import TasksPage from "./screens/tasks/Tasks-page";
import Earnings from "./screens/earnings/Earnings";
// import Redeem from "./screens/redeem/Redeem";
import Claim from "./screens/Claim/Claim";


function App() {
    const {webApp} = useTelegram()

    useEffect(() => {
        webApp.ready();
        webApp.expand();

    }, [webApp]);

    return (
        <div className="App">
            <Routes>
                <Route index element={<Main/>}/>
                <Route path={PATH_MAIN} element={<Main/>}/>
                <Route path={PATH_TASKS} element={<TasksPage/>}/>
                <Route path={PATH_EARNED} element={<Earnings/>}/>
                <Route path={PATH_REDEEM} element={<Claim/>}/>

            </Routes>
        </div>
    );
}

export default App;
