import axios from 'axios';
import { API_URL, BOT_ID, BRN_URL, MERCHANT_ID, TASK_ID } from "./Variables"
import { useTelegram } from "../../hooks/useTelegram";

export const getCustomerParams = async (params, code) => {
    let resp;
    const body = {
        init_data: params,
        entity: "customer",
        referral_code: code
    }

    await axios
        .post(`${BRN_URL}/service/${BOT_ID}/telegram/webapp/user`, body)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const registerInAffiliate = async (customerId, tokenCustomer) => {
    let resp;
    const body = {
        merchant_id: MERCHANT_ID,
        code: customerId,
    };
    let config = {
        headers: {
            'X-Access-Token': tokenCustomer || ''
        }
    };
    await axios
        .post(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/apply`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};
export const getLoyaltyData = async (customerId, tokenCustomer) => {
    let resp;
    let config = {
        headers: {
            'X-Access-Token': tokenCustomer || ''
        }
    };
    await axios
        .get(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/data?merchant_id=${MERCHANT_ID}`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => {
            console.log('data.error', error.message)
            if (error.message === 'Request failed with status code 401') {
                localStorage.clear();
                const webApp = useTelegram();
                if (webApp?.showAlert) {
                    webApp.showAlert('Please clear the history of the chat and return.');
                }
                window.confirm('Please clear the history of the chat and return.');
            }
        });
    return resp
};
export const getCustomerToken = async (tokenCustomer) => {
    let resp;
    let config = {
        headers: {
            'X-Access-Token': tokenCustomer || ''
        }
    };
    await axios
        .get(`${BRN_URL}/person/get/customer?ensure=true`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const claimPoints = async (tokenCustomer, taskId) => {
    let resp;
    let config = {
        headers: {
            'X-Access-Token': tokenCustomer || ''
        }
    };
    //https://api.brn.ai/tool/5EF5ZDM9QC/affiliate-program/loyalty-task/662b98319a5ea2e67d9a784b/claim
    await axios
        .post(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/loyalty-task/${taskId}/claim`, {}, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};
export const getPointsLimits = async (tokenCustomer) => {
    let resp;

    await axios
        .get(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/loyalty-task/${TASK_ID}?access_token=${tokenCustomer}`)
        .then(response => {
            resp = response?.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};
export const getTgToken = async (userData) => {
    let resp;
    let config = {
        headers: {
            'Access-Control-Allow-Origin': '*'
        }
    }
    let body = {
        initData: userData
    }
    await axios
        .post(`${API_URL}/customer`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const getTasks = async () => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        params: {
            limit: 300,
            offset: 0,
            merchant_id: MERCHANT_ID
        },
        headers: {
            'X-Access-Token': token
        }
    }

    await axios
        .get(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/loyalty-tasks`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const setInteraction = async (link) => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        params: {
            limit: 300,
            offset: 0,
            merchant_id: MERCHANT_ID
        },
        headers: {
            'X-Access-Token': token
        }
    }
    const body = {
        "type": "link-visited",
        "data": {
            "link": link
        }
    }

    await axios
        .post(`${BRN_URL}/tool/${BOT_ID}/interaction-tracker/track`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const getEarnings = async () => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        params: {
            limit: 300,
            offset: 0,
            merchant_id: MERCHANT_ID,
            status: 'pending_approvement',
            type: 'withdraw',
        },
        headers: {
            'X-Access-Token': token
        }
    }

    await axios
        .get(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/transactions`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const setWalletAddress = async (walletAddress) => {
    let resp;
    const token = localStorage.getItem('token');

    let config = {
        headers: {
            'X-Access-Token': token
        }
    }
    let body = {
        address: walletAddress,
        blockchain: 'solana'
    }
    await axios
        .post(`${BRN_URL}/person/crypto-wallet`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })

    return resp
};
export const deleteWalletAddress = async (walletAddress) => {
    let resp;
    const token = localStorage.getItem('token');

    let config = {
        headers: {
            'X-Access-Token': token
        }
    }

    await axios
        .delete(`${BRN_URL}/person/crypto-wallet`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
    return resp
};

//
export const getCustomersWallet = async () => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        headers: {
            'X-Access-Token': token || ''
        }
    };
    await axios
        .get(`${BRN_URL}/person/me`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
}
