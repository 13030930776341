import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import FooterFunction from "../../components/app/functions/FooterFunction";
import React, {useEffect, useState} from "react";
import {ReactComponent as PersonIcon} from '../../assets/person-img.svg';
import {getEarnings} from "../../logic/server/api";
import copyIcon from '../../assets/copy-line.svg'; 

const EarningsPage = () => {
    const [earnings, setEarnings] = useState([]);
    useEffect(() => {
        setEarnings([1,2,3, 4]);
        getEarningsRequest();
    }, []);

    async function getEarningsRequest() {
        const resp = await getEarnings();
        console.log(resp);
    }
    return (
        <TelegramScreen
            className={`main-wrapper`}  id='gesuredZone'>
            <div className='main-container main-container-dim'>
                <div className={'cards-container--top'}>
                    <div className={'card__tasks--title '}>
                        <p className={'text-title-knewawe card__tasks--title-accent'}>Earned from referrals:</p>
                        <p className={'text-bold card__tasks--title-text'}>6336287 PURR</p>
                    </div>
                    <div className={'cards__container'}>
                        {earnings.map((earning) => (
                            <div className={'card__task'}
                                 key={earning}
                            >
                                <PersonIcon/>
                                <div className={'card__column-to-row'}>
                                    <p className={'card__task--text'}>name</p></div>
                                <p className={'card__task--text-accent text-bold'}>2510006</p>
                            </div>
                        ))}

                    </div>
                    
                    <button className={'btn-for-copy-links'}>Copy invitation link <img src={copyIcon} alt="Copy" className="icon"></img></button>
                    
                </div>
                <div className='forms__bottom'>
                    <FooterFunction currentPage={'earnings'}/>
                </div>
            </div>
        </TelegramScreen>)


}

export default EarningsPage;
